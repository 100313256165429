/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/display-name */
import CustomSelect from '@/components/desktop/CustomSelect';
import { setCurrentArea } from '@/slices/area/slices/areaSlice';
import { clearPoints } from '@/slices/area/slices/searchFlightFormSlice';
import { ILangObj } from '@/slices/goyoloSeo/sliceType';
import { style } from '@/styles/globals';
import eventEmitter, { CLEAR_ANCHOR_POPPER_INPUT } from '@/utils/eventEmitter';
import { useAppDispatch, useAppSelector } from '@/utils/hook';
import { removeVietnameseTones } from '@/utils/stringConvert';
// import { pr } from '@/utils/pxToRem';
import { Stack, Typography, Box } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { components, ControlProps } from 'react-select';

interface PopperInputProps {
  children: any;
  title: string;
  type: any;
  setIsInputEmpty: any;
  debounceGetArea: any;
  isInputEmptyRef: any;
  vehicleType?: 'bus' | 'flight';
  onClickOutside?: () => any;
  lang?: ILangObj;
  point?: any;
  isFirstTime?: any;
}

const PopperInput: React.FC<PopperInputProps> = ({
  children,
  title,
  type,
  setIsInputEmpty,
  debounceGetArea,
  isInputEmptyRef,
  vehicleType,
  onClickOutside,
  lang,
  point,
  isFirstTime,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    window.scrollTo({
      top: 115,
      left: 0,
      behavior: 'smooth',
    });
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const dispatch = useAppDispatch();

  // const areaPoint = useAppSelector((state) => (type === 'from'
  //   ? state.searchFlightForm.startPoint
  //   : state.searchFlightForm.toPoint));
  const { startPointHome, toPointHome } = useAppSelector((store) => (vehicleType === 'bus' ? store.searchBusForm : store.searchFlightForm));

  let areaPoint = type === 'from' ? startPointHome : toPointHome;
  if (isFirstTime) {
    areaPoint = point;
  }
  const inputValue = useMemo(() => {
    let inputTemp: any = null;
    if (!isEmpty(areaPoint) && areaPoint) {
      inputTemp = {
        label: <></>,
        value: '',
      };
      if (vehicleType === 'bus') {
        inputTemp.label = (
          <Stack
            direction="row"
            alignItems="center"
          >
            <Typography variant="contentBold">{lang?.lang === 'en' ? removeVietnameseTones(areaPoint.location) : areaPoint.location}</Typography>
          </Stack>
        );
      } else if (areaPoint?.airports?.length > 1) {
        inputTemp.label = (
          <Stack
            direction="row"
            alignItems="center"
          >
            <Typography variant="body2">{lang?.lang === 'en' ? removeVietnameseTones(areaPoint.location) : areaPoint.location}</Typography>
            <Typography
              sx={{
                ml: 0.5,
                mt: '1px',
              }}
              variant="body2"
            >
              {' '}
              -
              {' '}
              {areaPoint.airports.length}
              {' '}
              {lang?.SearchBar?.flightsTab?.airports || 'sân bay'}
            </Typography>
          </Stack>
        );
      } else {
        inputTemp.label = (
          <Stack
            direction="row"
            alignItems="center"
          >
            <Typography variant="contentBold">{lang?.lang === 'en' ? removeVietnameseTones(areaPoint.location) : areaPoint.location}</Typography>
            <Typography
              sx={{
                ml: 0.5,
              }}
              variant="body2"
            >
              {' '}
              -
              {' '}
              {lang?.lang === 'en' ? areaPoint.airports?.[0]?.nameEn : areaPoint.airports?.[0]?.nameVn}
            </Typography>
          </Stack>
        );
      }
    }
    return inputTemp;
  }, [areaPoint]);

  const Control = useMemo(
    () => ({ children, ...props }: ControlProps<any, true>) => (
      <components.Control
        {...props}
        className="reactSelectControl"
      >
        <Stack
          direction="column"
          sx={{
            // p: pr`12px`,
            width: '100%',
          }}
          // alignItems="center"
        >
          <Typography
            variant="body2"
            color={style.color.black4}
            // fontWeight="500"
          >
            {title}
          </Typography>
          {children}

        </Stack>
      </components.Control>
    ),
    [],
  );

  const onExit = () => {
    dispatch(setCurrentArea({ currentArea: '' }));
    isInputEmptyRef.current = true;
    setIsInputEmpty(true);
  };
  const handleClickAway = () => {
    if (anchorEl) onClickOutside?.();
    setAnchorEl(null);
    isInputEmptyRef.current = true;
    setIsInputEmpty(true);
  };

  const handleInputChange = (value: string) => {
    if (value.length === 1) {
      // dispatch(clearPoints({ type }));
    }
    if (value.length === 0) {
      isInputEmptyRef.current = true;
      setIsInputEmpty(true);
      return;
    }
    if (value.length > 50) {
      return;
    }
    if (isInputEmptyRef.current) {
      setIsInputEmpty(false);
    }
    isInputEmptyRef.current = false;
    debounceGetArea.current(value, true);
  };

  useEffect(() => {
    if (!areaPoint) {
      setIsInputEmpty(true);
      isInputEmptyRef.current = true;
    }
  }, [areaPoint]);
  useEffect(() => {
    const event = eventEmitter.addListener(CLEAR_ANCHOR_POPPER_INPUT, () => {
      setAnchorEl(null);
    });
    return () => {
      event.remove();
    };
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Box onClick={handleClick}>
          <CustomSelect
            value={inputValue}
            Control={Control}
            locationType={type}
            placeholder=""
            onExit={onExit}
            onChange={(newValue, actionMeta) => {
              if (actionMeta.action === 'clear') {
                dispatch(clearPoints({ type }));
                isInputEmptyRef.current = true;
              }
            }}
            onInputChange={(value) => handleInputChange(value)}
          />
        </Box>
        <Popper
          style={{
            zIndex: 2000,
          }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
        >
          {children}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default PopperInput;
